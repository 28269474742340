import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

export default function Footer() {
  return (
    <div className="bg-white">
      <div className="relative py-24 bg-gray-800 ">
        <div className="absolute inset-0">
          <StaticImage
            src="../images/startup.png"
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Wes Bailey talking with Client"
            layout="fullWidth"
            loading="lazy"
            className="h-full w-full object-cover"
          />
          <div
            className="absolute inset-0 bg-gray-500 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="flex flex-col-reverse lg:flex-row  z-20 relative w-100 items-center">
          <div className="w-1/2 flex flex-col items-center justify-center ">
            <p className="text-2xl font-base font-montserrat text-white pb-4">
              <span className="border-b-2 pb-0.5">Get in Touch</span>
            </p>
            <div className="flex flex-col md:flex-row justify-center items-center text-white text-2xl">
              <a href="tel:519-870-5842">519-870-5842</a>
              <span className="px-5 hidden md:block ">|</span>
              <a href="mailto:wes@baileytech.ca">wes@baileytech.ca</a>
            </div>
          </div>

          <div className="w-1/2 flex items-center justify-center pull ">
            <StaticImage
              src="../images/bailey-tech-logo.png"
              width={430}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Custom Web Design"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
